import React from 'react';
import './Company.scss';
import Footer from '../../components/Footer';
import { Link, useNavigate } from 'react-router-dom';
import instarlogo from "../../images/instarlogo.jpeg"
import kakaologo from "../../images/login/kakao.png"

export default function Company() {
  
  let navigate = useNavigate();


  return (
    <div className='company'>

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <div className="subpage__menu">
          <div className="subpage__menu__title">수련회모아</div>
          <div className="subpage__menu__list">
            <div
              onClick={()=>{navigate('/company');}}
              className="subpage__menu__item subpage__menu__item--on"
            >
              소개
            </div>
            <div
              onClick={()=>{navigate('/company/advertise');}}
              className="subpage__menu__item"
            >
              광고및제휴
            </div>
          </div>
        </div>

        <div className="subpage__main">
          <div className="subpage__main__title">소개</div>
          <div className="subpage__main__content">
           
            <div className="notice-cover">
              <div className="cover">
                <div className="notice row">
                  <div className="notice-text-row">
                    <p>매번 수련회때마다 찾아 구해야 하는 것</p>
                    <p className="notice-text-right">"수련회 장소"</p>
                  </div>
                  <div className="notice-text-row">
                    <p>그때마다 가장 많이 했던 생각</p>
                    <p className="notice-text-right">"어디 괜챃은 수련회 장소가 없을까?"</p>
                  </div>
                  <div className="notice-text-row">
                    <p>그때마다 겪었던 불편함과 필요성</p>
                    <p className="notice-text-right">"수련회 장소들을 한번에 볼 수 없을까?"</p>
                  </div>
                </div>
              </div>

              <div className="cover">
                <div className="notice right">
                  <h2>수련회모아는 이를 해결하고 싶은 마음으로 만들어진 사이트입니다.</h2>
                  <p>- 때마다 수련회장소를 구하는데 받았던 스트레스</p>
                  <p>- 수련회를 준비하며 경험하는 적지않은 피로감</p>
                  <p>- 수련회에서 진행할 아이디어를 짜내는 어려움</p>
                  <h2>수련회모아에서 해결해보세요!</h2>
                </div>
              </div>

               <div className="cover">
                <div className="notice right">
                  <h2>관련링크</h2>
                  <a className='notice-row'
                    href='https://www.instagram.com/retreatmore_' target='_blank'
                  >
                   <img src={instarlogo}/>
                   <div className="notice-row-textbox">
                    <p>##</p>
                    <p>수련회모아 공식 인스타그램</p>
                   </div>
                  </a>
                  <a className='notice-row'
                    href='http://pf.kakao.com/_IxjWxoG' target='_blank'
                  >
                   <img src={kakaologo}/>
                   <div className="notice-row-textbox">
                    <p>##</p>
                    <p>수련회모아 공식 카카오채널</p>
                   </div>
                  </a>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>


      <Footer />
    </div>
  )
}

