import React, { useEffect, useState } from 'react';
import '../Retreat.scss';
import Footer from '../../../components/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MainURL from '../../../MainURL';
import MenuTemplate from '../MenuTemplate';
import PlaceInput from './PlaceInput';
import UniteInput from './UniteInput';
import CastingInput from './CastingInput';
// import CastingTemplate from './CastingTemplate';

export default function RequestMain(props:any) {

  let navigate = useNavigate();

  const [selectTab, setSelectTab] = useState(1);
 
  return (
    <div className="retreat">

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentNum={4} currentSubNum={0} navigate={navigate} />
        
        <div className="subpage__main">
          <div className="subpage__main__title">
            <h3>등록요청</h3>
          </div>
          
          <div className="subpage__main__select">
            <div className="select-row">
              <div className={selectTab === 1 ? 'select-btn selected' : 'select-btn'}
                onClick={()=>{setSelectTab(1)}}
              >
                <p style={{color: selectTab === 1 ? '#333' : '#BDBDBD'}}>수련회장소</p>
              </div>
              <div className={selectTab === 2 ? 'select-btn selected' : 'select-btn'}
                onClick={()=>{setSelectTab(2)}}
              >
                <p style={{color: selectTab === 2 ? '#333' : '#BDBDBD'}}>연합수련회</p>
              </div>
              <div className={selectTab === 3 ? 'select-btn selected' : 'select-btn'}
                onClick={()=>{setSelectTab(3)}}
              >
                <p style={{color: selectTab === 3 ? '#333' : '#BDBDBD'}}>수련회강사</p>
              </div>
            </div>
          </div>
          
          <div className="subpage__main__content">
            <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
              <p>등록요청을 하시면, 운영진이 검토후에 업로드 됩니다.</p>
            </div>
            { selectTab === 1 && <PlaceInput />}
            { selectTab === 2 && <UniteInput />}
            { selectTab === 3 && <CastingInput />}
          </div>
          
        </div>

      </div>
      <Footer/>
    </div>
  )
}



