import React, { useEffect, useState } from 'react';
import '../Community.scss';
import Footer from '../../../components/Footer';
import { useNavigate } from 'react-router-dom';
import ListTemplate from '../ListTemplate';
import MenuTemplate from '../MenuTemplate';
import ListGradeRequest from '../ListGradeRequest';

export default function BoardGradeRequest() {

  let navigate = useNavigate();
  const [currentMenu, setCurrentMenu] = useState(4);

  return (
    <div className='community'>

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} navigate={navigate} />

        <ListGradeRequest title='가입인사/등업신청' sort='graderequest' num={4}/>

      </div>

      <Footer />
    </div>
  )
}



