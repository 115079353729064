import React, { useCallback, useState } from 'react';
import './Input.scss'
import axios from 'axios'
import MainURL from "../../../MainURL";
import Select from 'react-select';
import DaumPostcode from 'react-daum-postcode';
import { useDropzone } from 'react-dropzone'
import imageCompression from "browser-image-compression";
import Loading from '../../../components/Loading';
import { CiCircleMinus } from 'react-icons/ci';
import { DropdownBox } from '../../../components/DropdownBox';
import { format } from 'date-fns';


export default function CastingInput (props:any) {

  const [sort, setSort] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [profile, setProfile] = useState('');
  const [inputImages, setInputImages] = useState<string[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [userContact, setUserContact] = useState('');
  
  // 종류 선택 ----------------------------------------------
  const sortOptions = [
    { value: '선택', label: '선택' },
    { value: '설교자', label: '설교자' },
    { value: '찬양사역자', label: '찬양사역자' },
    { value: '기타', label: '기타' }
  ];


   // 이미지 첨부 함수 ----------------------------------------------
   const currentDate = new Date();
   const date = format(currentDate, 'yyMMddHHmmss');
   const [imageLoading, setImageLoading] = useState<boolean>(false);
   const onDrop = useCallback(async (acceptedFiles: File[]) => {
     try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000
      };
      const resizedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          setImageLoading(true);
          const resizingBlob = await imageCompression(file, options);
          return resizingBlob;
        })
      );
      const regexCopy = /[^a-zA-Z0-9!@#$%^&*()\-_=+\[\]{}|;:'",.<>]/g;
      const fileCopies = resizedFiles.map((resizedFile, index) => {
        const regex = resizedFile.name.replace(regexCopy, '');
        const regexSlice = regex.slice(-15);
        return new File([resizedFile], `${date}_${regexSlice}`, {
          type: acceptedFiles[index].type,
        });
      });
      setImageFiles(fileCopies);
      const imageNames = acceptedFiles.map((file, index) => {
        const regex = file.name.replace(regexCopy, '');
        const regexSlice = regex.slice(-15);
        return `${date}_${regexSlice}`;
      });
      setInputImages(imageNames);
      setImageLoading(false);
     } catch (error) {
       console.error('이미지 리사이징 중 오류 발생:', error);
     }
   }, [setImageFiles]);
   const { getRootProps, getInputProps } = useDropzone({ onDrop }); 
  
   // 첨부 이미지 삭제 ----------------------------------------------
   const deleteInputImage = async (Idx:number) => {
     const copy =  [...imageFiles]
     const newItems = copy.filter((item, i) => i !== Idx);
     setImageFiles(newItems);
   };
 
   // 글쓰기 등록 함수 ----------------------------------------------

   const registerPost = async () => {
     const formData = new FormData();
     imageFiles.forEach((file, index) => {
       formData.append('img', file);
     });
      const getParams = {
        sort : sort,
        name : name,
        phone: phone,
        date: date,
        profile: profile,
        userContact: userContact,
        images : JSON.stringify(inputImages)
     }
     axios 
       .post(`${MainURL}/retreat/postscasting`, formData, {
         headers: {
           "Content-Type": "multipart/form-data",
         },
         params: getParams,
       })
       .then((res) => {
          if (res.data) {
            setSort('');
            setName('');
            setPhone('');
            setProfile('');
            setInputImages([]);
            setImageFiles([]);
            setUserContact('')
            alert('요청되었습니다. 운영진이 검토후에 업로드 됩니다.');
          }
       })
       .catch(() => {
         console.log('실패함')
       })
   };
  
  return  (
    <div className="apply">

      <div className="inner">
        <div className="inputbox">
          <div className='name'>
            <p>구분</p>
          </div>
          <DropdownBox
            widthmain='90%'
            height='40px'
            selectedValue={sort}
            options={sortOptions}
            handleChange={(e:any)=>{setSort(e.target.value)}}
          />
        </div>
        <div className="inputbox">
          <div className='name'>
            <p>이름</p>
          </div>
          <input type="text" onChange={(e)=>{setName(e.target.value)}} value={name} />
        </div>
        <div className="inputbox">
          <div className='name'>
            <p>연락처</p>
          </div>
          <input type="text" onChange={(e)=>{setPhone(e.target.value)}} value={phone} />
        </div>
        <div className="inputbox">
          <div className='name'>
            <p>프로필</p>
          </div>
          <textarea 
              className="textarea"
              value={profile}
              style={{outline:'none', lineHeight:'20px'}}
              onChange={(e)=>{setProfile(e.target.value)}}
            />
        </div>
        <p style={{fontSize:'14px'}}>* 요청된 자료가 이상이 있을시에, 운영진에서 연락이 갈수 있습니다.</p>
        <div className="inputbox">
          <div className='name'>
            <p>작성자 연락처</p>
          </div>
          <input type="text" onChange={(e)=>{setUserContact(e.target.value)}} value={userContact} />
        </div>

        {
          <div className="imageInputBox">
          {
            imageLoading ?
            <div style={{width:'100%', height:'100%', position:'absolute'}}>
              <Loading/>
            </div>
            :
            <div className='imageDropzoneCover'>
              <div {...getRootProps()} className="imageDropzoneStyle" >
                <input {...getInputProps()} />
                {
                  imageFiles.length > 0 
                  ? <div className='imageplus'>+ 다시첨부하기</div>
                  : <div className='imageplus'>+ 사진첨부하기</div>
                }
              </div>
            </div>
          }
          {
            imageFiles.length > 0 &&
            imageFiles.map((item:any, index:any)=>{
              return (
                <div key={index} className='imagebox'>
                  <img 
                    src={URL.createObjectURL(item)}
                  />
                  <p>{item.name}</p>
                  <div onClick={()=>{deleteInputImage(index);}}>
                    <CiCircleMinus color='#FF0000' size={20}/>
                  </div>
                </div>
              )
            })
          }
          </div>
        }

        <div className="buttonbox">
          <div className="button" onClick={registerPost}>
            <p>등록 요청 하기</p>
          </div>
        </div>
      </div>
      
      <div className="mobile">
        <h3>PC & 태블릿 환경에서만 입력 가능합니다.</h3>
      </div>

    </div>
  );
}
