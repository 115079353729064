import React, { useEffect, useState } from 'react';
import '../Retreat.scss';
import Footer from '../../../components/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MainURL from '../../../MainURL';
import { DropdownBox } from '../../../components/DropdownBox';
import MenuTemplate from '../MenuTemplate';
import PlaceTemplate from './PlaceTemplate';


export default function Place6jeju(props:any) {

  let navigate = useNavigate();

  interface ListProps {
    id : number,
    isView: string;
    placeName: string;
    sort: string;
    region: string;
    location: string;
    size: string;
    images: string[];
  }
  
  const [list, setList] = useState<ListProps[]>([]);
  const [selectSort, setSelectSort] = useState('all');
  
  const [searchWord, setSearchWord] = useState('');
  const [listAllLength, setListAllLength] = useState<number>(0);
  const [isResdataFalse, setIsResdataFalse] = useState<boolean>(false);

  // 게시글 가져오기
  const fetchPosts = async () => {
    const res = await axios.post(`${MainURL}/retreat/getdataplace`, {
      region : '제주도',
      sort : selectSort
    })
    if (res.data.data) {
      setIsResdataFalse(false);
      let copy: any = [...res.data.data];
      copy.reverse();
      setList(copy);
      setListAllLength(res.data.count);
    } else {
      setListAllLength(0);
      setIsResdataFalse(true);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [selectSort]);  


  interface PlaceGroup {
    region: string;
    placeList: ListProps[];
  }
  
  const placeData: PlaceGroup[] = list.reduce((acc: PlaceGroup[], curr: ListProps) => {
    const region = curr.region;
    const existingGroup = acc.find(group => group.region === region);
    const list: ListProps = {
      id : curr.id,
      isView: curr.isView,
      placeName: curr.placeName,
      sort: curr.sort,
      size: curr.size,
      region: curr.region,
      location: curr.location,
      images: curr.images,
    };
    if (existingGroup) {
        existingGroup.placeList.push(list);
    } else {
        acc.push({
            region: region,
            placeList: [list]
        });
    }
    return acc;
  }, []);

  // 글자 검색 ------------------------------------------------------
	const handleWordSearching = async () => {
    setList([]);
    if (searchWord.length < 2) {
      alert('2글자이상 입력해주세요')
    } else {
      const res = await axios.post(`${MainURL}/retreat/getdataplacesearch`, {
        region : '제주도',
        word: searchWord
      })
      if (res.data.data) {
        setIsResdataFalse(false);
        let copy: any = [...res.data.data];
        setList(copy);
        setListAllLength(res.data.count);
      } else {
        setListAllLength(0);
        setIsResdataFalse(true);
      }
    }
	};

 
  return (
    <div className="retreat">

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentNum={1} currentSubNum={6} navigate={navigate} />
        
        <div className="subpage__main">
          <div className="subpage__main__title">
            <h3>수련회장소</h3>
          </div>
          
          <div className="subpage__main__search">
            <div className="subpage__main__select__box">
              <p>유형별:</p>
              <DropdownBox
                widthmain='100px'
                height='35px'
                selectedValue={selectSort}
                options={[
                  { value: 'all', label: '전체' },
                  { value: '기도원', label: '기도원' },
                  { value: '교회', label: '교회' },
                  { value: '펜션', label: '펜션' },
                  { value: '수련원/수양관/연수원', label: '수련원/수양관/연수원'},
                  { value: '리조트/호텔', label: '리조트/호텔' }
                ]}
                handleChange={(e:any)=>{setSelectSort(e.target.value)}}
              />
            </div>
            
            <div className="subpage__main__search__box">
              <p style={{marginRight:'10px'}}>검색:</p>
              <input className="inputdefault width" type="text" placeholder='장소명, 주소 검색'
                value={searchWord} onChange={(e)=>{setSearchWord(e.target.value)}} 
                onKeyDown={(e)=>{if (e.key === 'Enter') {handleWordSearching();}}}
                />
              <div className="buttons" style={{margin:'20px 0'}}>
                <div className="btn" 
                  onClick={handleWordSearching}>
                  <p>검색</p>
                </div>
              </div>
              <div className="buttons" style={{margin:'20px 0'}}>
                <div className="btn" 
                  onClick={()=>{
                    setSearchWord('');
                    fetchPosts();
                  }}>
                  <p>초기화</p>
                </div>
              </div>
            </div>
          </div>

          <PlaceTemplate placeData={placeData} isResdataFalse={isResdataFalse} listAllLength={listAllLength} currentNum={1} currentSubNum={6} />
        </div>

      </div>
      <Footer/>
    </div>
  )
}



