import React, { useEffect, useRef, useState } from 'react';
import '../Retreat.scss';
import MainURL from '../../../MainURL';
import Footer from '../../../components/Footer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import MenuTemplate from '../MenuTemplate';
import axios from 'axios';


export default function CastingDetail (props:any) {

  const url = new URL(window.location.href);
  const ID = url.searchParams.get("id");
  const currentNum = url.searchParams.get("currentNum");
  const currentSubNum = url.searchParams.get("currentSubNum");

  let navigate = useNavigate();
 
  interface ListProps {
    id : number,
    sort: string;
    name: string;
    date: string;
    phone: string;
    profile: string;
    images: string[];
  }

  const [detailData, setDetailData] = useState<ListProps>();
  const [images, setImages] = useState<string[]>();
  // 게시글 가져오기
  const fetchPosts = async () => {
    const res = await axios.post(`${MainURL}/retreat/getdatacastingpart`, {
      id : ID
    })
    if (res.data) {
      const copy = {...res.data[0]}
      setDetailData(copy);
      const imgCopy = JSON.parse(copy.images);
      setImages(imgCopy);
    } 
  };

  useEffect(() => {
    fetchPosts();
  }, []);  


  const handleCopy = () => {
    navigator.clipboard.writeText(url.href).then(() => {
      alert('현재 링크 주소가 복사되었습니다.');
    }).catch((err) => {
      console.error('복사에 실패했습니다.', err);
    });
  };

  return (
    <div className="retreat">

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentNum={currentNum} currentSubNum={currentSubNum}  navigate={navigate} />
        
        <div className="subpage__main">
          <div className="subpage__main__title">
            <h3>{detailData?.name}</h3>
            <div className='postBtnbox'
              onClick={handleCopy}
            >공유하기</div>
          </div>

          <div className="subpage__main__content">
            <div className="main__content">
              <div className="textrow">
                <h3>구분</h3>
                <p>{detailData?.sort}</p>
              </div>
              <div className="textrow">
                <h3>연락처</h3>
                <a href={`tel:${detailData?.phone}`} target='_blank'
                   className='textrow__link'
                  >{detailData?.phone}</a>
              </div>
              <div className="textrow">
                <h3>프로필</h3>
                <p style={{whiteSpace:'pre-line'}}>{detailData?.profile}</p>
              </div>
              <div className='divider'></div>

              <div className="imagearea" style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                {
                  images?.map((item:any, index:any)=>{
                    return ( 
                      <img src={`${MainURL}/images/castingimage/${item}`} key={index} style={{width:'80%'}}/>
                    )
                  })
                } 
              </div>

              <div style={{textAlign:'right', width:'100%', marginTop:'100px', fontSize:'14px'}}>
                <h3>본 프로필과 사진은, 본인의 허락을 구한 후에, 올린 것입니다. </h3>
                <h3>업데이트 날짜: {detailData?.date}</h3>
              </div>

            </div>
          </div>

        </div>

      </div>
      <Footer/>
    </div>
  )
}



