import React, { useEffect, useState } from 'react';
import '../Retreat.scss';
import Footer from '../../../components/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MainURL from '../../../MainURL';
import MenuTemplate from '../MenuTemplate';
import CastingTemplate from './CastingTemplate';

export default function CastingAll(props:any) {

  let navigate = useNavigate();

  interface ListProps {
    id : number,
    isView: string;
    sort: string;
    name: string;
    images: string[];
  }
  
  const [list, setList] = useState<ListProps[]>([]);
  
  const [searchWord, setSearchWord] = useState('');
  const [listAllLength, setListAllLength] = useState<number>(0);
  const [isResdataFalse, setIsResdataFalse] = useState<boolean>(false);

  // 게시글 가져오기
  const fetchPosts = async () => {
    const res = await axios.post(`${MainURL}/retreat/getdatacasting`, {
      // season : 'all',
      // region : 'all'
    })
    if (res.data.data) {
      setIsResdataFalse(false);
      let copy: any = [...res.data.data];
      copy.reverse();
      setList(copy);
      setListAllLength(res.data.count);
    } else {
      setListAllLength(0);
      setIsResdataFalse(true);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);  


  interface PlaceGroup {
    sort: string;
    castingList: ListProps[];
  }
  
  const castingData: PlaceGroup[] = list.reduce((acc: PlaceGroup[], curr: ListProps) => {
    const sort = curr.sort;
    const existingGroup = acc.find(group => group.sort === sort);
    const list: ListProps = {
        id : curr.id,
        isView : curr.isView,
        sort: curr.sort,
        name: curr.name,
        images: curr.images
    };
    if (existingGroup) {
        existingGroup.castingList.push(list);
    } else {
        acc.push({
            sort: sort,
            castingList: [list]
        });
    }
    return acc;
  }, []).sort((a, b) => {
    const firstKeyword = "설교자";
    const secondKeyword = "찬양사역자";
    if (a.sort === firstKeyword) return -1;
    if (b.sort === firstKeyword) return 1; 
    if (a.sort === secondKeyword) return -1;
    if (b.sort === secondKeyword) return 1;
    return a.sort.localeCompare(b.sort);  
  });


  // 글자 검색 ------------------------------------------------------
	const handleWordSearching = async () => {
    setList([]);
    console.log(searchWord);
    if (searchWord.length < 2) {
      alert('2글자이상 입력해주세요')
    } else {
      const res = await axios.post(`${MainURL}/retreat/getdatacastingsearch`, {
        // season : 'all',
        // region : 'all',
        word: searchWord
      })
      if (res.data.data) {
        setIsResdataFalse(false);
        let copy: any = [...res.data.data];
        setList(copy);
        setListAllLength(res.data.count);
      } else {
        setListAllLength(0);
        setIsResdataFalse(true);
      }
    }
	};
 
  return (
    <div className="retreat">

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentNum={3} currentSubNum={0} navigate={navigate} />
        
        <div className="subpage__main">
          <div className="subpage__main__title">
            <h3>수련회강사</h3>
          </div>
          
          <div className="subpage__main__search">
           
            <div className="subpage__main__search__box">
              <p style={{marginRight:'10px'}}>검색:</p>
              <input className="inputdefault width" type="text" placeholder='이름 검색'
                value={searchWord} onChange={(e)=>{setSearchWord(e.target.value)}} 
                onKeyDown={(e)=>{if (e.key === 'Enter') {handleWordSearching();}}}
                />
              <div className="buttons" style={{margin:'20px 0'}}>
                <div className="btn" 
                  onClick={handleWordSearching}>
                  <p>검색</p>
                </div>
              </div>
              <div className="buttons" style={{margin:'20px 0'}}>
                <div className="btn" 
                  onClick={()=>{
                    setSearchWord('');
                    fetchPosts();
                  }}>
                  <p>초기화</p>
                </div>
              </div>
            </div>
          </div>

          <CastingTemplate castingData={castingData} isResdataFalse={isResdataFalse} listAllLength={listAllLength} currentNum={3} currentSubNum={0} />
          
        </div>

      </div>
      <Footer/>
    </div>
  )
}



