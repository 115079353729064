import React, { useEffect, useState } from 'react';
import '../Retreat.scss';
import Footer from '../../../components/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MainURL from '../../../MainURL';
import MenuTemplate from '../MenuTemplate';
import UniteTemplate from './UniteTemplate';

export default function UniteAll(props:any) {

  let navigate = useNavigate();

  interface ListProps {
    id : number,
    isView: string;
    retreatName: string;
    retreatDateStart : string;
    retreatDateEnd : string;
    placeName: string;
    region: string;
    location: string;
    images: string[];
  }
  
  const [list, setList] = useState<ListProps[]>([]);
  
  const [searchWord, setSearchWord] = useState('');
  const [listAllLength, setListAllLength] = useState<number>(0);
  const [isResdataFalse, setIsResdataFalse] = useState<boolean>(false);

  // 게시글 가져오기
  const fetchPosts = async () => {
    const res = await axios.post(`${MainURL}/retreat/getdataunite`, {
      season : 'all',
      region : 'all'
    })
    if (res.data.data) {
      setIsResdataFalse(false);
      let copy: any = [...res.data.data];
      copy.reverse();
      setList(copy);
      setListAllLength(res.data.count);
    } else {
      setListAllLength(0);
      setIsResdataFalse(true);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);  


  interface PlaceGroup {
    region: string;
    uniteList: ListProps[];
  }
  
  const uniteData: PlaceGroup[] = list.reduce((acc: PlaceGroup[], curr: ListProps) => {
    const region = curr.region;
    const existingGroup = acc.find(group => group.region === region);
    const list: ListProps = {
        id : curr.id,
        isView: curr.isView,
        retreatName : curr.retreatName,
        retreatDateStart : curr.retreatDateStart,
        retreatDateEnd : curr.retreatDateEnd,
        placeName: curr.placeName,
        region: curr.region,
        location: curr.location,
        images: curr.images,
    };
    if (existingGroup) {
        existingGroup.uniteList.push(list);
    } else {
        acc.push({
            region: region,
            uniteList: [list]
        });
    }
    return acc;
  }, []).sort((a, b) => a.region.localeCompare(b.region));

  // 글자 검색 ------------------------------------------------------
	const handleWordSearching = async () => {
    setList([]);
    if (searchWord.length < 2) {
      alert('2글자이상 입력해주세요')
    } else {
      const res = await axios.post(`${MainURL}/retreat/getdataunitesearch`, {
        season : 'all',
        region : 'all',
        word: searchWord
      })
      if (res.data.data) {
        setIsResdataFalse(false);
        let copy: any = [...res.data.data];
        setList(copy);
        setListAllLength(res.data.count);
      } else {
        setListAllLength(0);
        setIsResdataFalse(true);
      }
    }
	};

 
  return (
    <div className="retreat">

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentNum={2} currentSubNum={0} navigate={navigate} />
        
        <div className="subpage__main">
          <div className="subpage__main__title">
            <h3>기독교연합수련회</h3>
          </div>
          
          <div className="subpage__main__search">
           
            <div className="subpage__main__search__box">
              <p style={{marginRight:'10px'}}>검색:</p>
              <input className="inputdefault width" type="text" placeholder='수련회명, 장소명, 주소 검색'
                value={searchWord} onChange={(e)=>{setSearchWord(e.target.value)}} 
                onKeyDown={(e)=>{if (e.key === 'Enter') {handleWordSearching();}}}
                />
              <div className="buttons" style={{margin:'20px 0'}}>
                <div className="btn" 
                  onClick={handleWordSearching}>
                  <p>검색</p>
                </div>
              </div>
              <div className="buttons" style={{margin:'20px 0'}}>
                <div className="btn" 
                  onClick={()=>{
                    setSearchWord('');
                    fetchPosts();
                  }}>
                  <p>초기화</p>
                </div>
              </div>
            </div>
          </div>

          <UniteTemplate uniteData={uniteData} isResdataFalse={isResdataFalse} listAllLength={listAllLength} currentNum={2} currentSubNum={0} />
          
        </div>

      </div>
      <Footer/>
    </div>
  )
}



