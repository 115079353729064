import React, { useEffect, useRef, useState } from 'react';
import '../Retreat.scss';
import MainURL from '../../../MainURL';
import Footer from '../../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import MenuTemplate from '../MenuTemplate';
import axios from 'axios';


export default function UniteDetail (props:any) {

  const url = new URL(window.location.href);
  const ID = url.searchParams.get("id");
  const currentNum = url.searchParams.get("currentNum");
  const currentSubNum = url.searchParams.get("currentSubNum");

  let navigate = useNavigate();
  
  interface ListProps {
    id : number,
    retreatName: string;
    retreatDateStart : string;
    retreatDateEnd : string;
    placeName: string;
    region: string;
    location: string;
    size: string;
    address: string;
    homepage: string;
    phone: string;
    images: string[];
  }
  
  // 게시글 가져오기
  const fetchPosts = async () => {
    const res = await axios.post(`${MainURL}/retreat/getdataunitepart`, {
      id : ID
    })
    if (res.data) {
      const copy = {...res.data[0]}
      setDetailData(copy);
      const imgCopy = JSON.parse(copy.images);
      setSelectImage(imgCopy[0])
      setImages(imgCopy);
      addressAPI(copy.address);
    } 
  };

  useEffect(() => {
    fetchPosts();
  }, []);  

  const [detailData, setDetailData] = useState<ListProps>();
  const [images, setImages] = useState<string[]>();
  const [selectImage, setSelectImage] = useState();

  // 네이버 지도 구현하기
  const mapElement = useRef<HTMLDivElement | null>(null);
  const { naver } = window;
  const addressAPI = async (addressQuery:any) => {
    window.naver.maps.Service.geocode({
      query: (addressQuery)
    }, function(status:any, response:any) {
      const longitude = parseFloat(response.v2.addresses[0].y);
      const latitude = parseFloat(response.v2.addresses[0].x);
      if (!mapElement.current || !naver) return;
      const location = new naver.maps.LatLng(longitude, latitude);
      const mapOptions = {
        center: location,
        zoom: 11,
        zoomControl: true,
      };
      const map = new naver.maps.Map(mapElement.current, mapOptions);
      new naver.maps.Marker({
        position: location,
        map,
      });
    })
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(url.href).then(() => {
      alert('현재 링크 주소가 복사되었습니다.');
    }).catch((err) => {
      console.error('복사에 실패했습니다.', err);
    });
  };


  return (
    <div className="retreat">

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentNum={currentNum} currentSubNum={currentSubNum}  navigate={navigate} />
        
        <div className="subpage__main">
          <div className="subpage__main__title">
            <h3>{detailData?.retreatName}</h3>
            <div className='postBtnbox'
              onClick={handleCopy}
            >공유하기</div>
          </div>

          <div className="subpage__main__content">
            <div className="main__content">

              <div className="textrow">
                <h3>장소명</h3>
                <p>{detailData?.placeName}</p>
              </div>
              <div className="textrow">
                <h3>연락처</h3>
                <a href={`tel:${detailData?.phone}`} target='_blank'
                   className='textrow__link'
                  >{detailData?.phone}</a>
              </div>
              <div className="textrow">
                <h3>홈페이지</h3>
                <a href={detailData?.homepage} target='_blank'
                className='textrow__link'
                >{detailData?.homepage}</a>
              </div>
              <div className="textrow">
                <h3>주소</h3>
                <p>{detailData?.address}</p>
              </div>

              <div className="maparea">
                <div id="map" ref={mapElement} style={{ minHeight: '300px'}} />
              </div>

              <div className='divider'></div>

              <div className="imagearea" style={{display:'flex', flexDirection:'column', alignItems:'center'}}>
                {
                  images?.map((item:any, index:any)=>{
                    return ( 
                      <img src={`${MainURL}/images/uniteimage/${item}`} key={index} style={{width:'80%'}}/>
                    )
                  })
                } 
              </div>
            </div>
          </div>

        </div>

      </div>
      <Footer/>
    </div>
  )
}



