import React, { useEffect, useState } from 'react';
import '../Store.scss';
import Footer from '../../../components/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuTemplate from '../MenuTemplate';
import MainURL from '../../../MainURL';
import axios from 'axios';

export default function Books() {

  let navigate = useNavigate();
  const [currentMenu, setCurrentMenu] = useState(2);


  interface ListProps {
    id : number,
    isView: string;
    sort: string;
    name: string;
    images: string[];
  }
  
  const [list, setList] = useState<ListProps[]>([]);
  
  const [searchWord, setSearchWord] = useState('');
  const [listAllLength, setListAllLength] = useState<number>(0);
  const [isResdataFalse, setIsResdataFalse] = useState<boolean>(false);

  // 게시글 가져오기
  const fetchPosts = async () => {
    const res = await axios.post(`${MainURL}/retreat/getdatacasting`, {
      // season : 'all',
      // region : 'all'
    })
    if (res.data.data) {
      setIsResdataFalse(false);
      let copy: any = [...res.data.data];
      copy.reverse();
      console.log(copy);
      setList(copy);
      setListAllLength(res.data.count);
    } else {
      setListAllLength(0);
      setIsResdataFalse(true);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, []);  

  return (
    <div className='store'>

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentMenu={currentMenu} setCurrentMenu={setCurrentMenu} navigate={navigate} />
  
        <div className="subpage__main__content">

          <div className="main__content">
          {
            list.length > 0 && !isResdataFalse
            ?
            list.map((item:any, index:any) => (
              <div
                key={index}
                className="place__wrap--category"
                data-aos="fade-up"
              >
                <div className="place__title__row">
                  <div className="place__title">{item.sort}</div>
                </div>
                <div className="place__wrap--item">
                {/* {
                  item.castingList.map((subItem:any, subIndex:any) => {

                    const images = subItem.images ? JSON.parse(subItem.images) : '';

                    return subItem.isView === "true" && (
                      <div key={subIndex} className="place__item"
                        onClick={()=>{
                          window.scrollTo(0, 0);
                          // navigate(`/retreat/castingdetail?id=${subItem.id}&currentNum=${props.currentNum}&currentSubNum=${props.currentSubNum}`);
                        }}
                      >
                        <div className="place__img--cover">
                          <div className='imageBox' style={{height:'300px'}}>
                          {
                            images.length === 0
                            ? <p style={{fontSize:'14px'}}>등록된 사진이 없습니다.</p>
                            : <img src={`${MainURL}/images/castingimage/${images[0]}`} 
                                  alt={'등록된 사진이 없습니다.'} style={{maxHeight:'300px'}}/>
                          }
                          </div>
                        </div>
                        <div className="place__coname">
                          <p>{subItem.name}</p>
                        </div>
                    </div>
                    )
                  })
                } */}
                </div>
              </div>
            ))
            :
            <div
              className="place__wrap--category"
              data-aos="fade-up"
            >
              <div className="place__title">검색 결과가 없습니다.</div>
            </div>
          }
          </div>
        </div>
       
      </div>

      <Footer />
    </div>
  )
}



