import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';
import Banner from './Stores/Banner';
import Books from './Stores/Books';



export default function StoreMain() {
  return (
    <div>
    <Routes>
      <Route path="/" element={<Banner/>}/>
      <Route path="/books" element={<Books/>}/>
    </Routes>
  </div>
  )
}
