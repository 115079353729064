import React, { useEffect, useState } from 'react';
import '../Retreat.scss';
import Footer from '../../../components/Footer';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import MainURL from '../../../MainURL';
import MenuTemplate from '../MenuTemplate';
import { MdOutlineKeyboardDoubleArrowRight } from "react-icons/md";


export default function PlaceAll(props:any) {

  let navigate = useNavigate();
  const [currentNum, setCurrentNum] = useState(1);

  interface ListProps {
    id : number,
    isView: string;
    placeName: string;
    sort: string;
    region: string;
    location: string;
    size: string;
    images: string[];
  }
  
  const [list, setList] = useState<ListProps[]>([]);
  const [selectSort, setSelectSort] = useState('all');
  const [selectRegion, setSelectRegion] = useState('all');
  
  const [searchWord, setSearchWord] = useState('');
  const [listAllLength, setListAllLength] = useState<number>(0);
  const [isResdataFalse, setIsResdataFalse] = useState<boolean>(false);

  // 게시글 가져오기
  const fetchPosts = async () => {
    const res = await axios.post(`${MainURL}/retreat/getdataplace`, {
      region : selectRegion,
      sort : selectSort
    })
    if (res.data.data) {
      setIsResdataFalse(false);
      let copy: any = [...res.data.data];
      copy.reverse();
      setList(copy);
      setListAllLength(res.data.count);
    } else {
      setListAllLength(0);
      setIsResdataFalse(true);
    }
  };

  useEffect(() => {
    fetchPosts();
  }, [selectSort, selectRegion]);  


  interface PlaceGroup {
    region: string;
    placeList: ListProps[];
  }
  
  const placeData: PlaceGroup[] = list.reduce((acc: PlaceGroup[], curr: ListProps) => {
    if (!curr.isView) return acc;
    const region = curr.region;
    const existingGroup = acc.find(group => group.region === region);
    const list: ListProps = {
        id : curr.id,
        isView: curr.isView,
        placeName: curr.placeName,
        sort: curr.sort,
        size: curr.size,
        region: curr.region,
        location: curr.location,
        images: curr.images,
    };
    if (existingGroup) {
        existingGroup.placeList.push(list);
    } else {
        acc.push({
            region: region,
            placeList: [list]
        });
    }
    return acc;
  }, []).sort((a, b) => a.region.localeCompare(b.region));

  // 글자 검색 ------------------------------------------------------
	const handleWordSearching = async () => {
    setList([]);
    if (searchWord.length < 2) {
      alert('2글자이상 입력해주세요')
    } else {
      const res = await axios.post(`${MainURL}/retreat/getdataplacesearch`, {
        region : selectRegion,
        word: searchWord
      })
      if (res.data.data) {
        setIsResdataFalse(false);
        let copy: any = [...res.data.data];
        setList(copy);
        setListAllLength(res.data.count);
      } else {
        setListAllLength(0);
        setIsResdataFalse(true);
      }
    }
	};

 
  return (
    <div className="retreat">

      <div className="inner">

        {/* 왼쪽 메뉴바 */}
        <MenuTemplate currentNum={1} currentSubNum={0} navigate={navigate} />
        
        <div className="subpage__main">
          <div className="subpage__main__title">
            <h3>수련회장소</h3>
          </div>
          
          <div className="subpage__main__search">
           
            <div className="subpage__main__search__box">
              <p style={{marginRight:'10px'}}>검색:</p>
              <input className="inputdefault width" type="text" placeholder='장소명, 주소 검색'
                value={searchWord} onChange={(e)=>{setSearchWord(e.target.value)}} 
                onKeyDown={(e)=>{if (e.key === 'Enter') {handleWordSearching();}}}
                />
              <div className="buttons" style={{margin:'20px 0'}}>
                <div className="btn" 
                  onClick={handleWordSearching}>
                  <p>검색</p>
                </div>
              </div>
              <div className="buttons" style={{margin:'20px 0'}}>
                <div className="btn" 
                  onClick={()=>{
                    setSearchWord('');
                    fetchPosts();
                  }}>
                  <p>초기화</p>
                </div>
              </div>
            </div>
          </div>

          <div className="subpage__main__content">
            <div className="main__content">
            {
              placeData.length > 0 && !isResdataFalse
              ?
              placeData.map((item:any, index:any) => (
                  <div
                    key={index}
                    className="place__wrap--category"
                    data-aos="fade-up"
                  >
                    <div className="place__title__row">
                      <div className="place__title">
                        {item.region}
                      </div>
                      <div className='place__link'
                        onClick={()=>{
                          if (item.region === '서울/경기도') {navigate(`/retreat/1sk`); window.scrollTo(0, 0);};
                          if (item.region === '강원도') {navigate(`/retreat/2kw`); window.scrollTo(0, 0);};
                          if (item.region === '대전/충청도') {navigate(`/retreat/3dc`); window.scrollTo(0, 0);};
                          if (item.region === '광주/전라도') {navigate(`/retreat/4kj`); window.scrollTo(0, 0);};
                          if (item.region === '대구/부산/경상도') {navigate(`/retreat/5dbk`); window.scrollTo(0, 0);};
                          if (item.region === '제주도') {navigate(`/retreat/6jeju`); window.scrollTo(0, 0);};
                        }}
                      >
                        총{item.placeList.length}개 보러가기 <MdOutlineKeyboardDoubleArrowRight />
                      </div>
                    </div>
                    <div className="place__wrap--item">
                      { 
                        item.placeList.slice(0,6).map((subItem:any, subIndex:any) => {
                          
                          const images = subItem.images ? JSON.parse(subItem.images) : '';
  
                          return (
                            <div key={subIndex} className="place__item"
                              onClick={()=>{
                                window.scrollTo(0, 0);
                                navigate(`/retreat/placedetail?id=${subItem.id}&currentNum=1&currentSubNum=0`);
                              }}
                            >
                              <div className="place__img--cover">
                                <div className="namecard">
                                  <p>{subItem.location}</p>
                                </div>
                                <div className='imageBox'>
                                {
                                  // checkLoginData 
                                  // ?
                                  // <p>회원만 볼수 있습니다.</p>
                                  // :
                                  // <>
                                  // {
                                    images.length === 0
                                    ? <p style={{fontSize:'14px'}}>등록된 사진이 없습니다.</p>
                                    : <img src={`${MainURL}/images/placeimage/${images[0]}`} alt={'등록된 사진이 없습니다.'} />
                                  // }
                                  // </>
                                }
                                </div>
                              </div>
                              <div className="place__coname">
                                <p>{subItem.placeName}</p>
                              </div>
                              <div className="place__name">
                                <p>종류: {subItem.sort}</p>
                                <p>규모: {subItem.size}</p>
                              </div>
                          </div>
                          )
                        })
                      }
                    </div>
                  </div>
              ))
              :
              <div
                className="place__wrap--category"
                data-aos="fade-up"
              >
                <div className="place__title">검색 결과가 없습니다.</div>
              </div>
            }
            
            </div>
          </div>
        </div>

      </div>
      <Footer/>
    </div>
  )
}



