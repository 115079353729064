import '../Retreat.scss';
import { useNavigate } from 'react-router-dom';
import MainURL from '../../../MainURL';

export default function UniteTemplate (props:any) {

  let navigate = useNavigate();

  return (
    <div className="subpage__main__content">

      <div className="main__content">
      {
        props.uniteData.length > 0 && !props.isResdataFalse
        ?
        props.uniteData.map((item:any, index:any) => (
          <div
            key={index}
            className="place__wrap--category"
            data-aos="fade-up"
          >
            <div className="place__title__row">
              <div className="place__title">{item.region}</div>
            </div>
            <div className="place__wrap--item">
            {
              item.uniteList.map((subItem:any, subIndex:any) => {

                const images = subItem.images ? JSON.parse(subItem.images) : '';

                return subItem.isView === "true" && (
                  <div key={subIndex} className="place__item"
                    onClick={()=>{
                      window.scrollTo(0, 0);
                      navigate(`/retreat/unitedetail?id=${subItem.id}&currentNum=${props.currentNum}&currentSubNum=${props.currentSubNum}`);
                    }}
                  >
                    <div className="place__img--cover">
                      <div className="namecard">
                        <p>{subItem.location}</p>
                      </div>
                      <div className='imageBox' style={{height:'350px'}}>
                      {
                        images.length === 0
                        ? <p style={{fontSize:'14px'}}>등록된 사진이 없습니다.</p>
                        : <img src={`${MainURL}/images/uniteimage/${images[0]}`} 
                              alt={'등록된 사진이 없습니다.'} style={{maxHeight:'350px'}}/>
                      }
                      </div>
                    </div>
                    <div className="place__coname">
                      <p>{subItem.retreatName}</p>
                    </div>
                    <div className="place__name">
                      <p>기간: {subItem.retreatDateStart} ~ {subItem.retreatDateEnd}</p>
                      <p>장소: {subItem.placeName}</p>
                    </div>
                </div>
                )
              })
            }
            </div>
          </div>
        ))
        :
        <div
          className="place__wrap--category"
          data-aos="fade-up"
        >
          <div className="place__title">검색 결과가 없습니다.</div>
        </div>
      }
      </div>
    </div>
  )
}



