import React, { useEffect, useState } from 'react';
import './Store.scss';

export default function MenuTemplate(props:any) {

  interface SelectMenuProps {
    menuNum : number;
    title: string;
    url : string;
  }
  const SelectMenu: React.FC<SelectMenuProps> = ({ menuNum, title, url}) => {
    return (
      <div onClick={()=>{
        props.setCurrentMenu(menuNum);
        props.navigate(`/store${url}`)
      }}
        className={props.currentMenu === menuNum ? "subpage__menu__item subpage__menu__item--on" : "subpage__menu__item"}>
        {title}
      </div>
    )    
  };

  interface SelectSubMenuProps {
    menuSubNum : number;
    title: string;
    url : string;
  }
  const SelectSubMenu: React.FC<SelectSubMenuProps> = ({ menuSubNum, title, url}) => {
    return (
      <div onClick={()=>{
        
      }}
        className={parseInt(props.currentSubNum) === menuSubNum ? "subpage__menu__item__sub subpage__menu__item--on__sub" : "subpage__menu__item__sub"}>
        {title}
      </div>
    )    
  };

  return (
    <div className="subpage__menu">
      <div className="subpage__menu__title">수련회용품</div>
      <div className="subpage__menu__list menu__desktop">
        <SelectMenu title='현수막' menuNum={1} url={'/'}/>
        <div className="subpage__menu__list__sub">
          <SelectSubMenu title='시안확인' menuSubNum={1} url={'/1sk'}/>
          <SelectSubMenu title='주문하기' menuSubNum={2} url={'/2kw'}/>
        </div>
        <SelectMenu title='교재' menuNum={2} url={'/books'}/>
        <div className="subpage__menu__list__sub">
          <SelectSubMenu title='주문하기' menuSubNum={2} url={'/2kw'}/>
        </div>
      </div>

      <div className="subpage__menu__list menu__mobile">
        <SelectMenu title='현수막' menuNum={1} url={'/'}/>
        <SelectMenu title='교재' menuNum={2} url={'/books'}/>
      </div>
    </div>
  )
}



