import React, { useState, useEffect } from 'react';
import './Header.scss';
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { recoilLoginState, recoilUserData } from '../RecoilStore';

const Header: React.FC = () => {
  
  let navigate = useNavigate();
  const [isLogin, setIsLogin] = useRecoilState(recoilLoginState);
  const [userData, setUserData] = useRecoilState(recoilUserData);
  
  const menus = [
    { title: "수련회", url:"/retreat", 
      links: [
        {title:"수련회장소", subUrl:"/retreat"}, 
        {title:"기독연합수련회", subUrl:"/retreat/unite"},
        {title:"수련회강사", subUrl:"/retreat/casting"},
        {title:"등록요청", subUrl:"/retreat/request"},
      ]
    },
    // { title: "수련회용품", url:"/store", 
    //   links: [
    //     {title:"현수막", subUrl:"/store"}, 
    //     {title:"교재", subUrl:"/store/books"},
    //   ]
    // },
    { title: "커뮤니티", url:"/community", 
      links: [
        {title:"공지사항", subUrl:"/community"},
        {title:"프로그램 추천&공유", subUrl:"/community/program"},
        {title:"장소 후기", subUrl:"/community/review"},
        {title:"등업신청", subUrl:"/community/graderequest"},
      ] 
    },
    { title: "수련회모아", url:"/company", 
      links: [
        {title:"소개", subUrl:"/company"}, 
        {title:"광고및제휴", subUrl:"/company/advertise"},
      ]
    },
  ];

  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState<{ [key: number]: boolean }>({});

  const toggleMenu = () => {
      setMenuOpen(!menuOpen);
  };

  const toggleMobileMenu = (index: number) => {
      setMobileMenuOpen((prevState) => ({
          ...prevState,
          [index]: !prevState[index],
      }));
  };
  const handleLogout = async () => {
    setIsLogin(false);
    setUserData({
      userAccount : '',
      userNickName : '',
      userSort: '',
      userDetail : '',
      grade: ''
    })
    alert('로그아웃 되었습니다.')
    window.location.replace('/');
  };


  const alertLogin = () => {
    alert('로그인이 필요합니다.');
    navigate('/login');
  }
  const alertRequest = () => {
    alert('본 게시판은 정회원만 접근할 수 있습니다. 등업 게시판에서 등업신청을 해주세요.');
    navigate('/community/graderequest');
  }

  return (
    <div className="header">
      <div className="header-top">
        <div className="inner">
          <div className="container header-top-container">
            {
              isLogin 
              ? <p style={{color:'#fff', marginRight:'20px'}}>{userData.userNickName}님 환영합니다</p>
              : <p style={{color:'#fff', marginRight:'20px'}}>로그인해주세요</p>
            }
            {
              isLogin 
              ?
              <div className="header-button_wrap">
                <div className="header-button"
                  onClick={handleLogout}
                >로그아웃</div>
                <div className="header-button"
                  onClick={()=>{navigate('/mypage');}}
                >마이페이지</div>
              </div>
              :
              <div className="header-button_wrap">
                <div className="header-button"
                  onClick={()=>{navigate('/login');}}
                >로그인</div>
                <div className="header-button" 
                  onClick={()=>{navigate('/logister');}}
                >회원가입</div>
              </div>
            }
          </div>
        </div>
      </div>
      <div className="header-content">
        <div className="inner">
          <div className="container header-content-container">
              <div className="header-logo" 
                onClick={()=>{navigate('/')}}
              >
                <h1>수련회모아</h1>
              </div>
              <div className="header-menu">
                {
                  menus.map((item:any, index:any) => (
                    <div className="menu-item" key={index}>
                        <div className="menu-face" 
                          onClick={()=>{
                             if (isLogin) {
                              if (item.title === '수련회') {
                                if (userData.grade === '정회원') {
                                  navigate(item.url);
                                } else {
                                  alertRequest();
                                }
                              } else {
                                navigate(item.url);
                              }
                             } else {
                              alertLogin();
                             }
                          }}
                        >{item.title}</div>
                        <div className="menu-body">
                          { 
                            item.links.map((subItem:any, subIndex:any) => (
                              <div className="menu-part" key={subIndex}>
                                <div onClick={()=>{
                                    if (isLogin) {
                                      if (item.title === '수련회') {
                                        if (userData.grade === '정회원') {
                                          navigate(subItem.subUrl)
                                        } else {
                                          alertRequest();
                                        }
                                      } else {
                                        navigate(subItem.subUrl)
                                      }
                                     } else {
                                      alertLogin();
                                     }
                                  }}>{subItem.title}</div>
                              </div>
                            ))
                          }
                        </div>
                    </div>
                  ))
                }
              </div>
              <div className={`header-hamburger_menu ${menuOpen ? 'header-hamburger_menu--open' : ''}`}>
                  <div className="header-hamburger_icon" onClick={toggleMenu}></div>
                  <div className="header-mobile_menu">
                      <div className="mobile_menu-inner">
                          {
                            isLogin 
                            ?
                            <div className="mobile_menu-top">
                              <span className="mobile_menu-announce">{userData.userNickName}님 환영합니다.</span>
                              <div className="mobile_menu-button_wrap">
                                  <div className="header-button" onClick={handleLogout}>로그아웃</div>
                                  <div className="header-button" onClick={()=>{navigate("/mypage"); toggleMenu();}}>마이페이지</div>
                              </div>
                            </div>
                            :
                            <div className="mobile_menu-top">
                              <span className="mobile_menu-announce">로그인해 주세요</span>
                              <div className="mobile_menu-button_wrap">
                                  <div className="header-button" onClick={()=>{
                                    alert('모바일 환경에서, 일부 기기가 카카오 로그인이 불안정함에 따라, 카카오 로그인이 안될시에는 네이버 로그인을 해주세요.')
                                    navigate("/login"); toggleMenu();
                                  }}>로그인</div>
                                  <div className="header-button" onClick={()=>{
                                    alert('모바일 환경에서, 일부 기기가 카카오 회원가입이 불안정함에 따라, 카카오 회원가입이 안될시에는 네이버 회원가입을 해주세요.')
                                    navigate("/logister"); toggleMenu();}}
                                  >회원가입</div>
                              </div>
                            </div>
                          }
                          
                          <div className="mobile_menu-list">
                              {
                                menus.map((item:any, index:any) => (
                                  <div className={`mobile_menu-item ${mobileMenuOpen[index] ? 'mobile_menu-item--open' : ''}`} 
                                    key={index} onClick={() => 
                                      toggleMobileMenu(index)
                                    }>
                                      <div className="mobile_menu-item_inner">
                                          <div className={`mobile_menu-face ${mobileMenuOpen[index] ? 'mobile_menu-face--open' : ''}`}>
                                              <div className="mobile_menu-face_text" 
                                                onClick={()=>{
                                                  if (isLogin) {
                                                    if (item.title === '수련회') {
                                                      if (userData.grade === '정회원') {
                                                        navigate(item.url);
                                                      } else {
                                                        alertRequest();
                                                      }
                                                      toggleMenu();
                                                    } else {
                                                      navigate(item.url);
                                                      toggleMenu();
                                                    }
                                                   } else {
                                                      alertLogin();
                                                   }
                                                }}>{item.title}</div>
                                              <div className="mobile_menu-face_icon"></div>
                                          </div>
                                          <div className="mobile_menu-body">
                                              {
                                                item.links.map((subItem:any, subIndex:any) => (
                                                  <div className="mobile_menu-part"
                                                    onClick={()=>{
                                                      if (isLogin) {
                                                        if (item.title === '수련회') {
                                                          if (userData.grade === '정회원') {
                                                            navigate(subItem.subUrl);
                                                          } else {
                                                            alertRequest();
                                                          }
                                                          toggleMenu();
                                                        } else {
                                                          navigate(subItem.subUrl);
                                                          toggleMenu();
                                                        }
                                                       } else {
                                                          alertLogin();
                                                       }
                                                    }} key={subIndex}
                                                  >
                                                    {subItem.title}
                                                  </div>
                                              ))}
                                          </div>
                                      </div>
                                  </div>
                              ))}
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
