import React, { useCallback, useEffect, useRef, useState } from 'react';
import './Input.scss'
import axios from 'axios'
import MainURL from "../../../MainURL";
import { DaumPostcodeEmbed } from 'react-daum-postcode';
import { useDropzone } from 'react-dropzone'
import imageCompression from "browser-image-compression";
import Loading from '../../../components/Loading';
import { CiCircleMinus } from 'react-icons/ci';
import { DropdownBox } from '../../../components/DropdownBox';
import { format } from 'date-fns';

export default function PlaceInput (props:any) {


  const [placeName, setPlaceName] = useState('');
  const [sort, setSort] = useState('');
  const [region, setRegion] = useState('');
  const [size, setSize] = useState('');

  const [location, setLocation] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [homepage, setHomepage] = useState('');
  const [inputImages, setInputImages] = useState<string[]>([]);
  const [imageFiles, setImageFiles] = useState<File[]>([]);
  const [userContact, setUserContact] = useState('');
  
  // 종류 선택 ----------------------------------------------
  const sortOptions = [
    { value: '선택', label: '선택' },
    { value: '기도원', label: '기도원' },
    { value: '교회', label: '교회' },
    { value: '펜션', label: '펜션' },
    { value: '수련원/수양관/연수원', label: '수련원/수양관/연수원'},
    { value: '리조트/호텔', label: '리조트/호텔' }
  ];

  const regionOptions = [
    { value: '선택', label: '선택' },
    { value: '서울/경기도', label: '서울/경기도' },
    { value: '강원도', label: '강원도' },
    { value: '대전/충청도', label: '대전/충청도' },
    { value: '광주/전라도', label: '광주/전라도' },
    { value: '대구/부산/경상도', label: '대구/부산/경상도' },
    { value: '제주도', label: '제주도' },
  ];

  const sizeOptions = [
    { value: '선택', label: '선택' },
    { value: '50명이하', label: '50명이하' },
    { value: '50~100명', label: '50~100명' },
    { value: '100명이상', label: '100명이상' },
  ];

  // 주소 입력 함수
  const onCompletePost = (data:any) => {
    const copy = data.address;
    const sido = `${data.sido} ${data.sigungu}`
    setLocation(sido);
    setAddress(copy);
  };

   // 이미지 첨부 함수 ----------------------------------------------
   const currentDate = new Date();
   const date = format(currentDate, 'yyMMddHHmmss');
   const [imageLoading, setImageLoading] = useState<boolean>(false);
   const onDrop = useCallback(async (acceptedFiles: File[]) => {
    try {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1000
      };
      const resizedFiles = await Promise.all(
        acceptedFiles.map(async (file) => {
          setImageLoading(true);
          const resizingBlob = await imageCompression(file, options);
          return resizingBlob;
        })
      );
      const regexCopy = /[^a-zA-Z0-9!@#$%^&*()\-_=+\[\]{}|;:'",.<>]/g;
      const fileCopies = resizedFiles.map((resizedFile, index) => {
        const regex = resizedFile.name.replace(regexCopy, '');
        const regexSlice = regex.slice(-15);
        return new File([resizedFile], `${date}_${regexSlice}`, {
          type: acceptedFiles[index].type,
        });
      });
      setImageFiles(fileCopies);
      const imageNames = acceptedFiles.map((file, index) => {
        const regex = file.name.replace(regexCopy, '');
        const regexSlice = regex.slice(-15);
        return `${date}_${regexSlice}`;
      });
      setInputImages(imageNames);
      setImageLoading(false);
     } catch (error) {
       console.error('이미지 리사이징 중 오류 발생:', error);
     }
   }, [setImageFiles]);
   const { getRootProps, getInputProps } = useDropzone({ onDrop }); 
  
   // 첨부 이미지 삭제 ----------------------------------------------
   const deleteInputImage = async (Idx:number) => {
     const copy =  [...imageFiles]
     const newItems = copy.filter((item, i) => i !== Idx);
     setImageFiles(newItems);
   };
 
   // 글쓰기 등록 함수 ----------------------------------------------
   const registerPost = async () => {
     const formData = new FormData();
     imageFiles.forEach((file, index) => {
       formData.append('img', file);
     });
 
     const getParams = {
        placeName : placeName,
        sort: sort,
        region: region,
        location: location,
        size: size,
        address: address,
        phone: phone,
        date : date,
        homepage : homepage,
        userContact: userContact,
        postImage : JSON.stringify(inputImages)
     }

     axios 
       .post(`${MainURL}/retreat/postsplace`, formData, {
         headers: {
           "Content-Type": "multipart/form-data",
         },
         params: getParams,
       })
       .then((res) => {
         if (res.data) {
           setPlaceName('');
           setSort('선택');
           setRegion('선택');
           setLocation('');
           setSize('선택');
           setAddress('');
           setPhone('');
           setHomepage('');
           setInputImages([]);
           setImageFiles([]);
           setUserContact('')
           alert('요청되었습니다. 운영진이 검토후에 업로드 됩니다.');
         }
       })
       .catch(() => {
         console.log('실패함')
       })
   };
 

  return  (
    <div className="apply">

     <div className="inner">

        <div className="inputbox">
          <div className='name'>
            <p>형식</p>
          </div>
          <DropdownBox
            widthmain='90%'
            height='40px'
            selectedValue={sort}
            options={sortOptions}
            handleChange={(e:any)=>{setSort(e.target.value)}}
          />
        </div>
        <div className="inputbox">
          <div className='name'>
            <p>지역</p>
          </div>
          <DropdownBox
            widthmain='90%'
            height='40px'
            selectedValue={region}
            options={regionOptions}
            handleChange={(e:any)=>{setRegion(e.target.value)}}
          />
        </div>
        <div className="inputbox">
          <div className='name'>
            <p>장소명</p>
          </div>
          <input type="text" onChange={(e)=>{setPlaceName(e.target.value)}} value={placeName} />
        </div>
        <div className="inputbox">
          <div className='name'>
            <p>크기</p>
          </div>
          <DropdownBox
            widthmain='90%'
            height='40px'
            selectedValue={size}
            options={sizeOptions}
            handleChange={(e:any)=>{setSize(e.target.value)}}
          />
        </div>

        <div className="inputbox" style={{justifyContent:'flex-start'}}>
          <div className='name'>
            <p>주소찾기</p>
          </div>
          <DaumPostcodeEmbed
              style={{
                width:'90%',
                height:'350px'
              }}
              onComplete={onCompletePost} {...props} 
            >
          </DaumPostcodeEmbed>
        </div>

        <div className="inputbox">
          <div className='name'>
            <p>위치</p>
          </div>
          <input type="text" onChange={(e)=>{setLocation(e.target.value)}} value={location} />
        </div>
        <div className="inputbox">
          <div className='name'>
            <p>주소</p>
          </div>
          <input type="text" onChange={(e)=>{setAddress(e.target.value)}} value={address} />
        </div>
        <div className="inputbox">
          <div className='name'>
            <p>연락처</p>
          </div>
          <input type="text" onChange={(e)=>{setPhone(e.target.value)}} value={phone} />
        </div>
        <div className="inputbox">
          <div className='name'>
            <p>홈페이지</p>
          </div>
          <input type="text" onChange={(e)=>{setHomepage(e.target.value)}} value={homepage} />
        </div>
        <p style={{fontSize:'14px'}}>* 요청된 자료가 이상이 있을시에, 운영진에서 연락이 갈수 있습니다.</p>
        <div className="inputbox">
          <div className='name'>
            <p>작성자 연락처</p>
          </div>
          <input type="text" onChange={(e)=>{setUserContact(e.target.value)}} value={userContact} />
        </div>
        
        {
          <div className="imageInputBox">
          {
            imageLoading ?
            <div style={{width:'100%', height:'100%', position:'absolute'}}>
              <Loading/>
            </div>
            :
            <div className='imageDropzoneCover'>
              <div {...getRootProps()} className="imageDropzoneStyle" >
                <input {...getInputProps()} />
                {
                  imageFiles.length > 0 
                  ? <div className='imageplus'>+ 다시첨부하기</div>
                  : <div className='imageplus'>+ 사진첨부하기</div>
                }
              </div>
            </div>
          }
          {
            imageFiles.length > 0 &&
            imageFiles.map((item:any, index:any)=>{
              return (
                <div key={index} className='imagebox'>
                  <img 
                    src={URL.createObjectURL(item)}
                  />
                  <p>{item.name}</p>
                  <div onClick={()=>{deleteInputImage(index);}}>
                    <CiCircleMinus color='#FF0000' size={20}/>
                  </div>
                </div>
              )
            })
          }
          </div>
        }

        <div className="buttonbox">
          <div className="button" onClick={registerPost}>
            <p>등록 요청 하기</p>
          </div>
        </div>
      </div>
      
      <div className="mobile">
        <h3>PC & 태블릿 환경에서만 입력 가능합니다.</h3>
      </div>    

    </div>
  );
}
