import React from 'react'
import { Routes, Route, useNavigate } from 'react-router-dom';

import Post from './Post';
import Detail from './Detail';
import BoardNotice from './Boards/BoradNotice';
import BoardProgram from './Boards/BoardProgram';
import BoardReview from './Boards/BoardReview';
import BoardGradeRequest from './Boards/BoardGradeRequest';

export default function CommunityMain() {
  return (
    <div>
    <Routes>
      <Route path="/" element={<BoardNotice/>}/>
      <Route path="/program" element={<BoardProgram/>}/>
      <Route path="/review" element={<BoardReview/>}/>
      <Route path="/graderequest" element={<BoardGradeRequest/>}/>
      <Route path="/post" element={<Post/>}/>
      <Route path="/detail" element={<Detail/>}/>
    </Routes>
  </div>
  )
}
